import React from 'react'
import HeaderPaginas from '../../components/headerPaginas';
import Layout from "../../components/layout";
import Seo from "../../components/SEO/SEO"
import "../../styles/servicios.scss"
import CtaServicios from '../../components/ctaServicios';
import { IconContext } from "react-icons"
import { GrLock, GrScorecard, GrOverview, GrDocumentLocked, GrFingerPrint } from "react-icons/gr"


export default function Compliance(props) {
    return (
        <Layout>
            <Seo pathname={props.location.pathname} />
            <div className="cabecera-servicio">
                <HeaderPaginas titulo="Compliance" imagen="/img/cabeceras/compliance-bullhost.jpg" />
                <div className="intro-servicio">
                    <p>En Bullhost tenemos claro que todas las empresas deben poder moverse en el escenario normativo existente en la actualidad con la seguridad y la certeza de estar aplicándolas correctamente porque condicionan, de forma expresa, su actividad.</p>
                </div>
            </div>
            <div className="cuerpo-servicio">
                <section className="limitador">
                    <p>Nuestro equipo cuenta con consultores con un alto expertise y reconocimiento en el mercado que garantizan la implantación o adaptación de las normativas y estándares más habituales.</p>
                    <div className="fila-cards-compliance">
                        <IconContext.Provider value={{ fill: "#1f1f1f", stroke: "#1f1f1f", color: "#1f1f1f", size: "30%", className: "cpd-icono-svg" }}>
                            <div className="card-compliance">
                                <div className="card-compliance__icono">
                                    <GrOverview />
                                </div>
                                <div className="card-compliance__info">
                                    <h3>Plan Director de Seguridad</h3>
                                    <p>Estudio global que incluirá la revisión general del estado de seguridad de la ubicación, personal, infraestructura e informática de una organización, tanto a nivel externo como interno.</p>
                                </div>
                            </div>
                            <div className="card-compliance">
                                <div className="card-compliance__icono">
                                    <GrLock />
                                </div>
                                <div className="card-compliance__info">
                                    <h3>ISO 27001</h3>
                                    <p>A día de hoy, contar con la certificación en la norma ISO 27001 garantizará a todos tus clientes y proveedores que tu empresa vela por la seguridad de la información como marca el estándar internacional.</p>
                                </div>
                            </div>
                            <div className="card-compliance">
                                <div className="card-compliance__icono">
                                    <GrFingerPrint />
                                </div>
                                <div className="card-compliance__info">
                                    <h3>ENS: Esquema Nacional de Seguridad</h3>
                                    <p>Si eres Administración Pública, establecer la política de seguridad de la misma en la utilización de medios electrónicos a través de un ENS es vital.</p>
                                </div>
                            </div>
                            <div className="card-compliance card-4">
                                <div className="card-compliance__icono">
                                    <GrScorecard />
                                </div>
                                <div className="card-compliance__info">
                                    <h3>Plan Estratégico IT</h3>
                                    <p>Te ayudamos a alinear un plan estratégico de las IT con tu estrategia corporativa (ISO 38001).</p>
                                </div>
                            </div>
                            <div className="card-compliance">
                                <div className="card-compliance__icono">
                                    <GrDocumentLocked />
                                </div>
                                <div className="card-compliance__info">
                                    <h3>RGPD / LOPD</h3>
                                    <p>Proteger los datos es proteger a las personas. Para ello adecuar tu empresa a la RGPD que es obligatoria a partir del mes de mayo de 2018 ha supuesto y supone todo un reto.</p>
                                </div>
                            </div>
                        </IconContext.Provider>
                    </div>
                    <p>Tu empresa debe ser segura, y además, debe poder demostrarlo en un entorno empresarial cada vez más exigente con la ciberseguridad y con el cumplimiento de las normas.</p>
                </section>

            </div>
            <CtaServicios texto="No te pases de la raya, y contacta con nosotros." />
        </Layout>
    )
}
