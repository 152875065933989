import React from 'react'
import Boton from './boton'

export default function CtaServicios(props) {
    return (
        <section className="bloque-oscuro contacto-background" style={{ backgroundImage: `url(/img/cabeceras/BullHost_contacto_home.jpg)` }}>
            <div className="overlay-contacto"></div>
            <div className="header-inicio limitador">
                <div className="detalle destacado">
                    <p>Contacto</p>
                </div>
                <div className="titulo">
                    {props.texto
                        ? <h2>{props.texto}</h2>
                        : <h2>En Bullhost trabajamos mano a mano con nuestros clientes.</h2>
                    }
                    <p>¿Quieres un presupuesto personalizado?</p>
                    <Boton color="secundario" url="/contacto/" texto="Contactar" externo={false} />
                </div>
            </div>
        </section>
    )
}
